import React from 'react'
import PropTypes from 'prop-types'
import { TIMEOUT_WARNING_DURATION } from '../../constants/sessionTimeoutSettings'
import FocusLock from 'react-focus-lock'
import './index.scss'

import { translateComponent } from '../../util/translate'
const t = translateComponent('AboutToExpireModal')

const renderMessage = (timeLeftSeconds, refreshing) => {
  if (refreshing) return null
  if (timeLeftSeconds <= 0) return <div>{t('sessionExpired')}</div>
  return (
    <div>
      <div>{t('sessionAboutToExpire')}</div>
      {/* <a href=''>{t('keepLoggedIn')}</a> */}
    </div>
  )
}

const centerScreenStyles = timeLeft => {
  const time = timeLeft
  let styles = 'center-screen'
  if (time <= 10) {
    styles += ' last-chance'
  } else if (time <= 30) {
    styles += ' almost-last-chance'
  }
  if (time > 0) styles += ' active'
  return styles
}

const AboutToExpireModal = ({ timeLeftSeconds, load, refreshing }) => {
  if (!timeLeftSeconds || (timeLeftSeconds * 1000) > TIMEOUT_WARNING_DURATION) return null
  return (
    <FocusLock>
      <div className='about-to-expire'>
        <div onClick={load} className={centerScreenStyles(timeLeftSeconds)}>
          {refreshing ? <h3>{t('refreshing')}</h3> : <h1>{timeLeftSeconds >= 0 ? timeLeftSeconds : 0}</h1>}
          {renderMessage(timeLeftSeconds, refreshing)}
        </div>
      </div>
    </FocusLock>
  )
}

AboutToExpireModal.propTypes = {
  timeLeftSeconds: PropTypes.string.isRequired,
  load: PropTypes.func.isRequired,
  refreshing: PropTypes.bool.isRequired
}

export default AboutToExpireModal
