import React from 'react'
import './index.scss'

const Footer = () =>
  <div className='addnav-footer'>
    <div className='footer-top-wrapper'>
      <div className='footer-links-section'>

        <div className='communication-items'>
          <a target='_blank' rel='noopener noreferrer' href='https://www.add123.com/news/' title='News'>
            News
          </a> |
          <a target='_blank' rel='noopener noreferrer' href='https://www.add123.com/events/' title='Events'>
            Events
          </a> |
          <a target='_blank' rel='noopener noreferrer' href='https://www.add123.com/support/' title='Support'>
            Support
          </a> |
          <a target='_blank' rel='noopener noreferrer' href='https://www.add123.com/contact/' title='Contact Us'>
            Contact Us
          </a>
        </div>

        <div className='social-media'>
          <a target='_blank' rel='noopener noreferrer' href='https://www.facebook.com/AutoDataDirect/'>
            <span className='addicon addicon-facebook-logo social facebook' />
          </a>
          <a target='_blank' rel='noopener noreferrer' href='https://twitter.com/autodatadirect'>
            <span className='addicon addicon-twitter-logo social' />
          </a>
          <a target='_blank' rel='noopener noreferrer' href='https://www.linkedin.com/company/autodatadirect/'>
            <span className='addicon addicon-linkedin-logo social' />
          </a>
          <a target='_blank' rel='noopener noreferrer' href='https://add123.com/contact/'>
            <span className='addicon addicon-contact social' />
          </a>
        </div>
      </div>

      <div className='footer-about-section'>
        <div className='footer-about-add'>
          <img className='logo-footer' alt='Auto Data Direct Logo' src='https://add123.com/images/logo-add.svg' />
          <div>1830 E. Park Ave, Suite 1</div>
          <div>Tallahassee, FL 32301</div>
          <div className='spacer' />
          <div><b>Sales:</b> 855-300-3455</div>
          <div><b>Support:</b> 866-923-3123</div>
        </div>
      </div>

    </div>

    <div className='footer-bottom-section'>
      <div className='footer-copy'>©{new Date().getFullYear()} AutoDataDirect, Inc. All Rights Reserved</div>

      <a target='_blank' rel='noopener noreferrer' href='https://www.add123.com/about-add/privacy-policy'>Privacy Policy</a>
      &nbsp;
      |
      &nbsp;
      <a target='_blank' rel='noopener noreferrer' href='https://www.add123.com/tou'>Terms of Use</a>
    </div>

  </div>

export default Footer
